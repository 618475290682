<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Chương trình thưởng hoa hồng AdHoc'">
          <template v-slot:toolbar>
            <div class="row">
              <div class="ml-3">
                <router-link to="/reward/upsert-reward-adhoc">
                  <button
                    type="button"
                    class="btn btn-primary font-weight-bolder btn-sm"
                  >
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                    mới
                  </button>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row class="d-flex flex-row justify-content-between">
                    <b-input
                      placeholder="Tìm kiếm"
                      v-model="search"
                      append-icon="search"
                      single-line
                      hide-details
                      class="btn-sm w-25"
                      v-on:input="fetchData()"
                    ></b-input>
                    <router-link to="/reward/history-reward-adhoc">
                      <button
                        type="button"
                        class="btn btn-secondary font-weight-bolder btn-sm pt-3"
                      >
                        <i class="fas fa-history"></i>
                        Lịch sử chỉnh sửa
                      </button>
                    </router-link>
                </b-row>
              </b-form>
            </div>
            <!-- Table session -->
            <b-table
              :items="accessories"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(creator)="row">
                <div class="d-flex flex-column">
                  <span>{{ row.item.creator }}</span>
                  <span>{{
                    moment(row.item.createdAt).format('DD-MM-YYYY HH:mm')
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermission('REWARD_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số nhóm sản phẩm:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="totalPages"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      accessories: [],
      installments: [],
      onLoading: false,
      isSearching: false,
      jobName: '',
      storeName: '',
      currentPage: 0,
      totalPages: 0,
      count: 0,
      totalItems: 0,
      page: 1,
      search: '',
      employees: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '22%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '28%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          formatter: (value) => {
            return moment(value).format('DD-MM-YYYY');
          },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          formatter: (value) => {
            return value ? moment(value).format('DD-MM-YYYY') : '';
          },
        },

        {
          key: 'creator',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        { key: 'actions', label: '' },
      ],
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chương trình thưởng hoa hồng AdHoc' },
    ]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    moment,
    editItem: function (item) {
      this.$router.push({
        name: 'upsert-reward-adhoc',
        query: { id: item.id },
      });
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }

      let params = {
        page: this.page,
        limit: 10,
        name: this.search,
      };

      ApiService.setHeader();
      ApiService.query('/reward-adhoc', { params }).then((response) => {
        this.accessories = [];
        this.count = 0;
        this.totalPages = response.data.data.totalPages;
        this.totalItems = response.data.data.totalItems;
        response.data.data.result.map((item) => {
          this.count++;
          let data = {
            count: this.count,
            id: item.id,
            name: item.name,
            startDate: item.startDate,
            endDate: item.endDate,
            description: item.description,
            creator: item.creator,
            createdAt: item.createdAt,
          };
          this.accessories.push(data);
        });
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa chương trình thưởng hoa hồng AdHoc!',
        text: 'Bạn có chắc muốn xóa chương trình thưởng hoa hồng AdHoc này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete('reward-adhoc' + '/' + item.id).then(() => {
        this.makeToastSuccess('Xoá dữ liệu thành công!');
        return this.fetchData();
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkStatus: function (item) {
      if (item.status === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped></style>
